
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import {articleTypes, IArticleCategory, IArticleItem} from "@/models/article_model";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons-vue'
import ImageView from '@/components/ImageView.vue'
import PageUtil from "@/utils/page_util";
import {IArticlePlatform, IArticlePlatformCate, IArticles} from "@/models/logistics_article_model";

interface IState {
  list: IArticles[],
  total: number;
  loading: boolean;
  category: IArticlePlatformCate[]
  searches: any
  editItem?: IArticles
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '文章标题',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '所属分类',
    dataIndex: 'documentation_cate_id',
    key: 'documentation_cate_id',
  },
  {
    title: '排序',
    dataIndex: 'weight',
    key: 'weight',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
    align: 'right'
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: 250
  },
];

export default defineComponent({
  name: 'ArticleList',
  components: {EditOutlined, CheckOutlined, CloseOutlined, ImageView},
  setup() {
    const state: IState = reactive({
      platformList: [],
      list: [],
      total: 0,
      loading: false,
      category: [{
        id: 0,
        "title": "全部文章",
        "weight":0,
        "status": 1,
        children: []
      }],
      subCategory: [],
      editItem: undefined,
      searches: {
        keywords: '',
        cate_id: 0,
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize
      }
    })


    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      params.page_no = params.page;
      params.page_size = params.limit;
      params.keyword = params.keywords;
      StorageUtil.setParams(params)
      delete params.total
      delete params.pid
      state.loading = true
      const res = await ApiLogistics.articleList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.list || []
      }
    }

    // 删除文章
    const onDel = async (item: IArticles) => {
      const res = await ApiLogistics.articleDelete(item.id)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 去新增编辑
    const goSet = (item?: IArticles) => {
      let params = {}
      if (item) {
        params = {id: item.id}
      }
      router.push({path: '/articles/set', query: params}).then()
    }
    // 详情
    const goDetail = (item: IArticleItem) => {
      router.push({path: '/article/detail', query: {id: item.id}}).then()
    }

    // 修改状态
    const changeStatus = async (item: IArticles) => {
      let params = {
        "id": item.id,
        "status": item.status ? 0 : 1
      }
      state.loading = true
      const res = await ApiLogistics.articleStatusChange(params)
      state.loading = false
      if (res && res.error_code === 0) {
        getList()
      }
    }

    // 获取分类
    const getCategory = async () => {
      const res = await ApiLogistics.articleCateTree({})
      if (res && res.error_code === 0) {
        state.category[0].children = res.data || []
      }
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    // 获取分类名称
    const getCateName = (cateId: number, array?: IArticlePlatformCate[]) => {
      if (!array) {
        array = state.category[0].children;
      }
      let result:IArticlePlatformCate | undefined = undefined;
      array?.map((item:IArticlePlatformCate) => {
        if (item.id === cateId) {
          result = item;
        }
        if (!result && item.children) {
          result = getCateName(cateId, item.children);
        }
        return item;
      })
      return result;
    }

    onMounted(() => {
      getCategory()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      goSet,
      onDel,
      goDetail,
      pagination,
      handleTableChange,
      onSearch,
      resetSearch,
      articleTypes,
      getList,
      getCateName,
      changeStatus
    }
  }
})
